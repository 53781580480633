let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
let source  = process.env.REACT_APP_SOURCE || ""

export default {
  en: {
    mainTitle: 'OPEN BANKING DEMO',
    logo: '/images/logo.png',
    links: [
      {
        path: '/catalog',
        label: 'API Catalog',
        separator: false,
        megaMenu: 'apiCatalog'
      },
      {
        path: '/use-cases/onboarding',
        label: 'Use Cases',
        separator: false,
        megaMenu: 'useCases'
      },
      // {
      //   path: '/build',
      //   label: 'Innovation Programs',
      //   separator: false,
      //   megaMenu: 'innovationPrograms'
      // },
      {
        path: '/about',
        label: 'About Us',
        separator: false,
        megaMenu: ''
      },
      {
        path: '',
        label: '',
        separator: false,
        megaMenu: ''
      },
      {
        path: `${marketplace}/tunldemo/login?source=${source}`,
        label: 'Login',
        separator: false,
        megaMenu: ''
      },
      {
        path: '',
        label: '',
        separator: true,
        megaMenu: ''
      },
      {
        path: `${marketplace}/tunldemo/register?source=${source}`,
        label: 'Sign Up',
        separator: false,
        megaMenu: ''
      },
    ],
    megaMenus: {
      apiCatalog: {
        messageTitle: 'API Catalogue',
        message: "A comprehensive catalogue of ready to consume APIs",
        columns: [
          [
            {
              icon: 'lock_open',
              text: 'Authorize',
              path: '/catalog/#authorize'
            },
            {
              icon: 'account_balance_wallet',
              text: 'Accounts',
              path: '/catalog/#accounts'
            },
            {
              icon: 'account_circle',
              text: 'Profile',
              path: '/catalog/#profile'
            },
            {
              icon: 'family_restroom',
              text: 'Beneficiaries',
              path: '/catalog/#beneficiaries'
            },
          ],
          [
            {
              icon: 'sync',
              text: 'Transfers',
              path: '/catalog/#transfers'
            },
            {
              icon: 'payment',
              text: 'Payments',
              path: '/catalog/#payments'
            },
            {
              icon: 'receipt',
              text: 'Deposits',
              path: '/catalog/#deposits'
            },
            {
              icon: 'local_atm',
              text: 'Withdrawals',
              path: '/catalog/#withdrawals'
            },
          ],
          [
            {
              icon: 'local_shipping',
              text: 'Logistics',
              path: '/catalog/#logistics'
            },
            {
              icon: 'visibility',
              text: 'PIN',
              path: '/catalog/#pin'
            },
            {
              icon: 'vpn_key',
              text: 'Token',
              path: '/catalog/#token'
            },
            {
              icon: 'notifications_active',
              text: 'Notifications',
              path: '/catalog/#notifications'
            },
          ]
        ]
      },
      useCases: {
        messageTitle: 'Use Cases',
        message: "Read our use cases and let's start building together!",
        columns: [
          [
            {
              text: 'Onboarding: new account for new client',
              path: '/use-cases/onboarding'
            },
            {
              text: 'Open account for existing client',
              path: '/use-cases/openaccount'
            },
            {
              text: 'Loyalty/rewards program',
              path: '/use-cases/loyalty'
            },
            {
              text: 'Withdraw and deposit cash',
              path: '/use-cases/withdraw'
            },
          ],
          [
            {
              text: 'Payment from account',
              path: '/use-cases/payment'
            },
            {
              text: 'Transfers between accounts',
              path: '/use-cases/transfers'
            },
            {
              text: 'Check account attributes',
              path: '/use-cases/attributes'
            },
            {
              text: 'Check account balance and movements',
              path: '/use-cases/accountbalance'
            },
          ],
          [
            {
              text: 'Geolocation',
              path: '/use-cases/geolocation'
            },
            {
              text: 'Chatbot',
              path: '/use-cases/chatbot'
            },
          ],
        ]
      },
      innovationPrograms: {
        messageTitle: 'Innovation Programs',
        message: "Join our Innovation Programs, share your solutions, and make a difference!",
        columns: [
          [
            {
              text: 'Customer Engagement Challenge',
              path: '/innovation-programs',
              highlight: true
            },
            {
              text: 'Customized Loyalty Rewards',
              path: '/innovation-programs'
            },
            {
              text: 'Aggregated Loyalty Rewards',
              path: '/innovation-programs'
            },
            {
              text: 'Create your own program',
              path: '/innovation-programs',
              highlight: true
            },
            {
              text: 'Add your need here',
              path: '/innovation-programs'
            },
            {
              text: "Developer's Guide",
              path: '/guide',
              highlight: true
            },
          ],
          [
            {
              text: 'Ongoing Needs',
              path: '/needs',
              highlight: true
            },
            {
              text: 'Enhanced Mobile Wallet',
              path: '/innovation-programs'
            },
            {
              text: 'Automated Payments Engine',
              path: '/innovation-programs'
            },
            {
              text: 'Fraudulent Behavior Detecion',
              path: '/innovation-programs'
            },
            {
              text: 'Onboarding with Digital Documents',
              path: '/innovation-programs'
            },
            {
              text: 'ID Documentation Validation',
              path: '/innovation-programs'
            },
          ],
        ]
      },
    }
  },
  es: {
    mainTitle: 'OPEN BANKING DEMO',
    logo: '/images/logo.png',
    links: [
      {
        path: '/catalog',
        label: 'API Catalog',
        separator: false,
        megaMenu: 'apiCatalog'
      },
      {
        path: '/use-cases',
        label: 'Use Cases',
        separator: false,
        megaMenu: 'useCases'
      },
      {
        path: '/build',
        label: 'Innovation Programs',
        separator: false,
        megaMenu: 'innovationPrograms'
      },
      {
        path: '/about',
        label: 'About Us',
        separator: false,
        megaMenu: ''
      },
      {
        path: '',
        label: '',
        separator: false,
        megaMenu: ''
      },
      {
        path: `${marketplace}/tunldemo/login?source=${source}`,
        label: 'Login',
        separator: false,
        megaMenu: ''
      },
      {
        path: '',
        label: '',
        separator: true,
        megaMenu: ''
      },
      {
        path: `${marketplace}/tunldemo/register?source=${source}`,
        label: 'Sign Up',
        separator: false,
        megaMenu: ''
      },
    ],
    megaMenus: {
      apiCatalog: {
        messageTitle: 'API Catalogue',
        message: "A comprehensive catalogue of ready to consume APIs",
        columns: [
          [
            {
              icon: 'lock_open',
              text: 'Authorize',
              path: '/catalog'
            },
            {
              icon: 'account_balance_wallet',
              text: 'Accounts',
              path: '/catalog'
            },
            {
              icon: 'account_circle',
              text: 'Profile',
              path: '/catalog'
            },
            {
              icon: 'family_restroom',
              text: 'Beneficiaries',
              path: '/catalog'
            },
          ],
          [
            {
              icon: 'sync',
              text: 'Transfers',
              path: '/catalog'
            },
            {
              icon: 'payment',
              text: 'Payments',
              path: '/catalog'
            },
            {
              icon: 'receipt',
              text: 'Deposits',
              path: '/catalog'
            },
            {
              icon: 'local_atm',
              text: 'Withdrawals',
              path: '/catalog'
            },
          ],
          [
            {
              icon: 'local_shipping',
              text: 'Logistics',
              path: '/catalog'
            },
            {
              icon: 'visibility',
              text: 'PIN',
              path: '/catalog'
            },
            {
              icon: 'vpn_key',
              text: 'Token',
              path: '/catalog'
            },
            {
              icon: 'notifications_active',
              text: 'Notifications',
              path: '/catalog'
            },
          ]
        ]
      },
      useCases: {
        messageTitle: 'Use Cases',
        message: "Read our use cases and let's start building together!",
        columns: [
          [
            {
              text: 'Onboarding: new account for new client',
              path: '/use-cases/onboarding'
            },
            {
              text: 'Open account for existing client',
              path: '/use-cases/openaccount'
            },
            {
              text: 'Loyalty/rewards program',
              path: '/use-cases/loyalty'
            },
            {
              text: 'Withdraw and deposit cash',
              path: '/use-cases/withdraw'
            },
          ],
          [
            {
              text: 'Payment from account',
              path: '/use-cases/payment'
            },
            {
              text: 'Transfers between accounts',
              path: '/use-cases/transfers'
            },
            {
              text: 'Check account attributes',
              path: '/use-cases/attributes'
            },
            {
              text: 'Check account balance and movements',
              path: '/use-cases/accountbalance'
            },
          ],
          [
            {
              text: 'Geolocation',
              path: '/use-cases/geolocation'
            },
            {
              text: 'Chatbot',
              path: '/use-cases/chatbot'
            },
          ],
        ]
      },
      innovationPrograms: {
        messageTitle: 'Innovation Programs',
        message: "Join our Innovation Programs, share your solutions, and make a difference!",
        columns: [
          [
            {
              text: 'Customer Engagement Challenge',
              path: '/innovation-programs',
              highlight: true
            },
            {
              text: 'Customized Loyalty Rewards',
              path: '/innovation-programs'
            },
            {
              text: 'Aggregated Loyalty Rewards',
              path: '/innovation-programs'
            },
            {
              text: 'Create your own program',
              path: '/innovation-programs',
              highlight: true
            },
            {
              text: 'Add your need here',
              path: '/innovation-programs'
            },
            {
              text: "Developer's Guide",
              path: '/guide',
              highlight: true
            },
          ],
          [
            {
              text: 'Ongoing Needs',
              path: '/needs',
              highlight: true
            },
            {
              text: 'Enhanced Mobile Wallet',
              path: '/innovation-programs'
            },
            {
              text: 'Automated Payments Engine',
              path: '/innovation-programs'
            },
            {
              text: 'Fraudulent Behavior Detecion',
              path: '/innovation-programs'
            },
            {
              text: 'Onboarding with Digital Documents',
              path: '/innovation-programs'
            },
            {
              text: 'ID Documentation Validation',
              path: '/innovation-programs'
            },
          ],
        ]
      },
    }
  },
}
