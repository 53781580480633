import React, { useState } from 'react';
import { Link } from "react-router-dom";
import Icon from '@material-ui/core/Icon';

const MegaMenu = (props)=>{
  const { data, selected, openSubMenu } = props;
    return(
      <div style={{width: '100%', height: '100%'}}>
        {data.columns &&
          <div className="submenu-container">
            <div className="message">
              <h1>{data.messageTitle}</h1>
              <p>{data.message}</p>
            </div>
            <div className="columns">
              {data.columns.map((column, index) =>
                <div className="column" key={`column-${index}`} style={{width: `${(((100 - 4)/data.columns.length))}%`}}>
                  {column.map((item, i) =>
                    <a className="use-case" key={i} href={item.path} onClick={(e) => openSubMenu('')}>
                      {item.icon? <Icon>{item.icon}</Icon> : ''}
                      <p className={item.highlight? "highlighted-item" : ""}>{item.text}</p>
                      {item.highlight? <Icon style={{color: "var(--main-color)"}}>chevron_right</Icon> : ''}
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        }
      </div>
    )
  }

export default MegaMenu
