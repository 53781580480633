
export default {
  en: {
    welcome: {
      image: '/images/welcome-pic.png',
      title: 'Welcome to tunl.®',
      texts: ['Gain access to the tools and information you need to consume APIs. Explore the ways you can build with tunl.®'],
      subtitle: 'Unlock your potential through our Innovation Programs',
      button: {
        text: 'Innovation Programs',
        link: '/innovation-programs'
      }
    },
    cards: [
      {
        title: 'Browse APIs',
        text: 'Navigate through our API Catalogue',
        link: '/catalog',
        icon: 'api'
      },
      {
        title: 'Start Building',
        text: "Let's get started!",
        link: '/guide',
        icon: 'code'
      },
    ],
    slides: [
      {
        title: '',
        image: '/images/home-carousel-1.png',
        texts: [
          'Open Banking emerges in the financial industry, as a new model of collaboration with third party providers. Through APIs, banks, fintechs, and other service providers can leverage the synergies of an integrated ecosystem with the ultimate goal of providing a better user experience.'
        ],
        buttonText: 'Browse APIs',
        buttonLink: '/catalog'
      },
      {
        title: '25 most innovative & transformative bankers',
        image: '/images/tunl_image-01.png',
        texts: [
          "Open Banking keep tune to the winds of change in Canada",
          "FiCANEX Technology has released tunl. ,  an API-based platform that provides a robust technology infrastructure to integrate with financial institutions core systems. This has been possible because of the Software Agreement license, signed with Finconecta at the beginning of the summer.",
          "FICANEX Technology will white label the 4WRD platform for delivery as a key component of its tunl.TM platform and plans to initially provide the platform and related services to Canadian credit union and banking clients.",
          "tunl. platform will allow financial institutions to integrate multiple solutions, through one single connection, to accelerate their digital transformation and create new business opportunities within the financial services industry.",
          "Great things are near to become true."
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Partner with Us',
        texts: [
          'Connect your innovative products and services with our customers, we make it simple and easy! Scale globally and achieve time to market by using our standardized APIs, we include unlimited testing in our Sandbox! It is easier than you think!'
        ],
        image: '/images/bottom-box-img.png',
        buttonLink: '/about',
        buttonText: 'About Us'
      }
  },
  es: {
    welcome: {
      image: '/images/welcome-pic.png',
      title: 'Welcome to tunl.®',
      texts: ['Gain access to the tools and information you need to consume APIs. Explore the ways you can build with tunl.®'],
      subtitle: 'Unlock your potential through our Innovation Programs',
      button: 'Innovation Programs'
    },
    cards: [
      {
        title: 'Browse APIs',
        text: 'Navigate through our API Catalogue',
        link: '#',
        icon: 'api'
      },
      {
        title: 'Start Building',
        text: "Let's get started!",
        link: '#',
        icon: 'code'
      },
    ],
    slides: [
      {
        title: '',
        image: '/images/home-carousel-1.png',
        texts: [
          'Open Banking emerges in the financial industry, as a new model of collaboration with third party providers. Through APIs, banks, fintechs, and other service providers can leverage the synergies of an integrated ecosystem with the ultimate goal of providing a better user experience.'
        ],
        buttonText: 'Browse APIs',
        buttonLink: '/catalog'
      },
      {
        title: '25 most innovative & transformative bankers',
        image: 'https://cdn.pixabay.com/photo/2018/01/15/07/51/woman-3083382_1280.jpg',
        texts: [
          'We are excited to introduce our 2019 Top 25 Most Innovative Bankers in Latin America & the Caribbean.',
          'This year’s list of the bankers – and banks! - showcases a variety of ways that institutions are leveraging technology and innovation to transform for the digital age.'
        ],
        buttonText: '',
        buttonLink: ''
      },
    ],
    bottomBox:{
        title: 'Partner with Us',
        texts: [
          'Connect your innovative products and services with our customers, we make it simple and easy! Scale globally and achieve time to market by using our standardized APIs, we include unlimited testing in our Sandbox! It is easier than you think!'
        ],
        image: '/images/bottom-box-img.png',
        buttonLink: '/about',
        buttonText: 'About Us'
      }
  },
}
