import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import AnimateHeight from 'react-animate-height';


const styles = theme => ({
  expansiveSection: {
    marginTop: "20px",
    boxSizing: "border-box",
    width: "100%",
    backgroundColor: "#FFFFFF",
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    padding: "18px 0",
    transition: "0.5s"
  },
  expandButtonContainer: {
    position: "absolute",
    top: "25px",
    right: "18px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0 2px 4px 0 rgba(132,130,130,0.41)",
    borderRadius: "100%",
    height: "36px",
    width: "36px"
  },
  expandButton: {
    color: "#00998E",
    "& *": {
      color: "#00998E"
    }
  },
})


const ExpansiveContainer = ({
	classes,
  children,
  containerIndex
}) => {

	const [height, setHeight] = useState(50);

	const toggleHeight = () => {
		height === "auto" ? setHeight(50) : setHeight("auto")
	}

	return (
		<div
      className={classes.expansiveSection}
      style={{padding: height === "auto"? "" : "18px 0", borderTop: containerIndex !== 0? "1px solid grey" : ''}}>
			<AnimateHeight
				duration={ 500 }
				height={ height }
				style={{width: "100%", transition: "0.5s"}}
			>
        {React.cloneElement(children, { height })}
			</AnimateHeight>
			<div className={classes.expandButtonContainer}>
				<IconButton className={classes.expandButton} onClick={toggleHeight} color="inherit">
					{ height === "auto" ? <KeyboardArrowUp/> : <KeyboardArrowDown /> }
				</IconButton>
			</div>
		</div>
  );
}

export default withStyles(styles)(ExpansiveContainer);
