import React from 'react';
import Icon from '@material-ui/core/Icon';
import APICategory from './components/APICategory.js'
import './styles/catalog.css';


class Catalog extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      cardFront: true,
      selectedAPIs: []
    }
    this.flipCard = this.flipCard.bind(this);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  flipCard(selected){
    let apiFronts = this.state.selectedAPIs.map(a => a)
    apiFronts.includes(selected)?
      apiFronts.splice(apiFronts.indexOf(selected), 1):
      apiFronts.push(selected)
    this.setState({cardFront: !this.state.cardFront, selectedAPIs: apiFronts})
  }

  render(){
    const { data, language } = this.props

    return(
      <div>
        <div className="oval" style={{flexDirection: "column", alignItems: "flex-start", padding: "0 120px", justifyContent: "center"}}>
          <img className="oval-img" src="/images/oval.svg" alt="oval"/>
          <a href="/guide" style={{display: "flex", alignItems: "center"}}>
            <p style={{color: "white"}}>Don´t know how to start? Check out our Developer’s guide</p>
            <Icon style={{color: "white"}}>arrow_right_alt</Icon>
          </a>
        </div>
        {data.map((c, i) =>
          <div key={i} className="category-section">
            <APICategory
              data={c}
              flipCard={this.flipCard}
              cardFront={this.state.cardFront}
              category={c.apiGroupName}
              selectedAPIs={this.state.selectedAPIs}
              />
          </div>
        )}
      </div>
    )
  }
}

export default Catalog;
