import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Social from './components/Social.js'
import Need from './components/Need.js'

import './styles/innovation.css';

const styles = theme => ({
  tabs: {
    width: "100%",
    marginBottom: "24px",
    // borderBottom: "2px solid #E6E9EF"
  },
  tab: {
    width: "25%",
    maxWidth: "none",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: "400",
    borderBottom: "2px solid #E6E9EF"
  },
  tabsIndicator: {
    width: "25%"
  },
})



class InnovationPrograms extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0,
      language: '',
      selectedNeeds: []
    }
    this.flipCard = this.flipCard.bind(this);
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  handleChangeTabIndex = (e, tab) => {
    this.setState({tabIndex: tab})
  }

  flipCard(selected){
    let needFronts = this.state.selectedNeeds.slice()
    needFronts.includes(selected)?
      needFronts.splice(needFronts.indexOf(selected), 1):
      needFronts.push(selected)
    this.setState({selectedNeeds: needFronts})
  }


  render(){
    const { data, classes } = this.props
    const { tabIndex, selectedNeeds } = this.state

    return(
      <div style={{width:'100vw', height: '100vh'}}>
        <div className="main">
          <div className="image-container">
            <img src="/images/home-carousel-1.png" alt="challenge-img"/>
          </div>
          <div className="description">
            <p className="date">{data.programs[0].dueDate}</p>
            <p className="title">{data.programs[0].title}</p>
            <p className="text">{data.programs[0].description}</p>
          </div>
        </div>
        <div className="tabs-container">
          <Tabs
            value={tabIndex}
            onChange={this.handleChangeTabIndex}
            style={{width: "100%", color: "#232A3D", paddingBottom: "50px"}}
            TabIndicatorProps={{
              style: { backgroundColor: "#00998E"}
            }}
            classes={{indicator: classes.tabsIndicator, root: classes.tabs}}
          >
            <Tab label="What is it?" className={classes.tab}/>
            <Tab label="Requirements" className={classes.tab}/>
            <Tab label="Benefits" className={classes.tab}/>
            <Tab label="Key dates" className={classes.tab}/>
          </Tabs>
          <p className="tab-content">{data.programs[0].tabs[tabIndex]}</p>
        </div>
        <div className="needs-container">
          <p className="title">{data.programs[0].title}</p>
          <div className="category-container" style={{flexDirection: "row"}}>
            {data.programs[0].needs.map((need, idx) =>
              <div
                className="category-data"
                key={`need-${idx}`}
                onClick={(e) => this.flipCard(`need-${idx}`)}
                style={{background: "none"}}
                >
                <Need
                  data={need}
                  challengeName={data.programs[0].title}
                  clickedNeed={selectedNeeds.includes(`need-${idx}`)? true : false}
                  />
              </div>
            )}
          </div>
        </div>
        <Social/>
      </div>
    )
  }
}

export default  withStyles(styles)(InnovationPrograms);
