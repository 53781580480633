import React from 'react';
import Icon from '@material-ui/core/Icon';
import { Link } from "react-router-dom";
import HomeCarousel from './components/HomeCarousel.js'
import ImageBox from './components/ImageBox.js'
import Social from './components/Social.js'
import './styles/home.css';


class Home extends React.Component{
  componentDidMount(){
    window.scrollTo(0, 0);
  }


  render(){
    const { welcome, cards, slides, bottomBox } = this.props.data

    return(
      <div>

        <div className="welcome">
          <div className="welcome-text">
            <p className="welcome-title">{welcome.title}</p>
            {welcome.texts.map((t,i) =>
              <p key={`${i}`}>{t}</p>
            )}
            {/* <p className="welcome-subtitle">{welcome.subtitle}</p>
            <a href={`${welcome.button.link}`}><button>{welcome.button.text}</button></a> */}
          </div>
          <img src={welcome.image} alt="welcome-pic"/>
        </div>

        <div className="cards-container">
          {cards.map((c,i) =>
            <Link to={c.link} key={`key_${i}`}>
              <div className="card">
                <div className="card-icon">
                  {c.icon.includes('/')?
                    <img src={c.icon} alt="chart"/> :
                    <Icon>{c.icon}</Icon>
                  }
                </div>
                <div className="card-text">
                  <p className="card-title">{c.title}</p>
                  <p>{c.text}</p>
                </div>
              </div>
            </Link>
          )}
        </div>

        <div className="home-carousel-container">
          <p>Innovate, test, play, repeat</p>
          <HomeCarousel data={slides}/>
        </div>

        <div className="partner-container">
          <ImageBox
            data={bottomBox}
            imageSide="right"
            />
        </div>

        {/* <Social/>
        <div className="api-terms">
          <img height="47px" src="/images/logo-white.png"/>
          <a style={{color: "white", fontSize: "25px"}} href={`#`} target="_blank" rel="noopener noreferrer">Support</a>
        </div> */}
      </div>
    )
  }
}

export default Home;
