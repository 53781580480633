let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
let source  = process.env.REACT_APP_SOURCE || ""

export default {
  en: {
    mainTitle: 'ABOUT US',
    boxes: [
      {
        title: 'What is Tunl Demo for Developers?',
        texts: [
          'Tunl Demo for Developers is a place where developers and third party providers can connect with our core system for the consumption of APIs. It offers direct access to an increasing number of APIs, tools, and technical support which help developers and third party providers build apps in a secure and efficient manner.'
        ],
        image: '/images/devteam.png',
        buttonText: "Register Here",
        buttonLink: `${marketplace}/tunldemo/register?source=${source}`
      },
      {
        title: 'Why use Tunl Demo for Developers?',
        texts: [
          'Tunl Demo for Developers offers you direct access to a growing number of APIs, tools, and support that can help you build easier, faster, and more secure ways to power e-commerce. Our platform allows developers and third party providers to embrace crowdsourcing and open collaboration by experimenting, testing, running POCs, and providing fully developed solutions.'
        ],
        image: '/images/product.png',
        buttonText: 'Browse our API Catalogue',
        buttonLink: '/catalog'
      },
      {
        title: 'How to use Tunl Demo for Developers?',
        texts: [
          'After identifying the relevant APIs that your project requires and completing your registration, you can test connectivity with our BankBot core banking simulator. Once the development and initial tests are completed, you can enter into our Sandbox and be able to run pilot tests in a safe environment. By having the certifications and authorization of Tunl Demo, you can then go on to the production stage of the process'
        ],
        image: '/images/program.png',
        buttonText: 'Start building here',
        buttonLink: '/guide'
      }
    ]
  },
  es: {
    mainTitle: 'ABOUT US',
    boxes: [
      {
        title: 'What is Tunl Demo for Developers?',
        texts: [
          'Tunl Demo for Developers is a place where developers and third party providers can connect with our core system for the consumption of APIs. It offers direct access to an increasing number of APIs, tools, and technical support which help developers and third party providers build apps in a secure and efficient manner.'
        ],
        image: 'https://cdn.pixabay.com/photo/2015/01/02/00/01/telephone-586268_1280.jpg',
        buttonText: "Register Here",
        buttonLink: `${marketplace}/tunldemo/register?source=${source}`
      },
      {
        title: 'Why use Tunl Demo for Developers?',
        texts: [
          'Tunl Demo for Developers offers you direct access to a growing number of APIs, tools, and support that can help you build easier, faster, and more secure ways to power e-commerce. Our platform allows developers and third party providers to embrace crowdsourcing and open collaboration by experimenting, testing, running POCs, and providing fully developed solutions.'
        ],
        image: 'https://cdn.pixabay.com/photo/2019/05/05/18/58/girl-4181395_1280.jpg',
        buttonText: 'Browse our API Catalogue',
        buttonLink: '/catalog'
      },
      {
        title: 'How to use Tunl Demo for Developers?',
        texts: [
          'After identifying the relevant APIs that your project requires and completing your registration, you can test connectivity with our BankBot core banking simulator. Once the development and initial tests are completed, you can enter into our Sandbox and be able to run pilot tests in a safe environment. By having the certifications and authorization of Tunl Demo, you can then go on to the production stage of the process'
        ],
        image: 'https://cdn.pixabay.com/photo/2019/05/05/18/58/girl-4181395_1280.jpg',
        buttonText: 'Start building here',
        buttonLink: '/guide'
      }
    ]
  },

}
