import React from 'react';
import Icon from '@material-ui/core/Icon';
import ReactCountryFlag from "react-country-flag"
let source  = process.env.REACT_APP_SOURCE || ""

const countries = require('country-list');


const APICategory = (props)=>{
  const { data, cardFront, clickedAPI } = props
  const countryCodes = []
  let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"
  if('apiCountries' in data && data.apiCountries.length) data.apiCountries.map((c,i) =>
      countryCodes.push(countries.getCode(c))
    )


  return(
    <div className="api-container" style={clickedAPI? {transform: 'rotateY(180deg)'} : {}}>
      <div  className="card-front">
        <img
          className="api-icon"
          src={'apiIcon' in data && data.apiIcon? `${marketplace}/api/logo/${data.apiIcon}` : '/icons/icon-placeholder.png'}
          alt={'apiIcon' in data? data.apiIcon : ''}/>
        <div className="api-front-data">
          <div className="api-title">
            <p>{'apiName' in data? data.apiName : ''}</p>
            <p>{'apiDescription' in data? data.apiDescription : ''}</p>
          </div>
          <div className="tags">
            {'apiStage' in data && data.apiStage?
              <div>
                <span className="stage"/>
                <p>{data.apiStage}</p>
              </div>
               : ''
             }
            {'apiVisibility' in data && data.apiVisibility?
              <div>
                <span className="visibility"/>
                <p>{data.apiVisibility}</p>
              </div>
               : ''
             }
          </div>
        </div>
      </div>
      <div className="card-back">
        <img
          className="api-icon-small"
          src={'apiIcon' in data && data.apiIcon? `${marketplace}/api/logo/${data.apiIcon}` : '/icons/icon-placeholder.png'}
          alt={'apiIcon' in data? data.apiIcon : ''}/>
        <p className="main-title">{'apiName' in data? data.apiName: ''}</p>
        <div className="api-back-data">
          <div className="bottom-container">
            <p className="title">Certifications:</p>
            <div className="certifications">
              {'apiCertifications' in data && data.apiCertifications.length? data.apiCertifications.map((c,i) =>
                <p key={`key_${i}}`} className="certification">{c}</p>
              ): <p className="something-missing">Certifications Missing</p>}
            </div>
          </div>
          <div className="register">
            <a href="https://marketplace.finconecta.com/tunldemo/login?source=devportal">
              <p className="title">Documentation</p>
              <Icon>subject</Icon>
            </a>
            <a href={`${marketplace}/tunldemo/register?source=${source}`} target="_blank" rel="noopener noreferrer">
              <p className="title">Subscribe</p>
              <Icon style={{color: "var(--main-color)"}}>arrow_right_alt</Icon>
            </a>
          </div>
        </div>
     </div>
    </div>
  )
}

export default APICategory
