let marketplace  = process.env.REACT_APP_MARKETPLACE_URL || "https://marketplace.dev.finconecta.com"

export default {
  en: {
    groups: [
      {
        title: "About Us",
        questions: [
          {
            question: "Who can Participate?",
            answer: [
              "The Registration process to tunl. Bank Demo developer Portal is open to any Solution Provider or developer. All registrations submitted are subject to Tunl Demo Bank. ",
              "To register for an Innovation Program launched by tunl. Demo bank, please read the respective information and follow the instructions. Only Solution Providers that accept the terms and conditions will be approved to participate."
            ]
          },
          {
            question: "What are the Open Innovation Programs?",
            answer: [
              "Innovation Programs aim to efficiently find the right partnership to resolve tunl. Demo Bank’s specific needs. Stay tuned for upcoming Innovation Programs.   Reaching out to the fintech ecosystem, tunl. Demo Bank, will select among the participating Solution Providers the technology that better fits its digital strategy."
            ]
          },
        ]
      },
      {
        title: "APIs",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "Please register to tunl. Bank Demo Developer Portal and provide all the required information. Once your registration has been approved,  review tunl. Demo Bank API Library and identify those that are needed to support your product. Request access to those APIs by clicking in Approval Request. Your request will be processed and reviewed. "
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "We currently have 50 standardized and fully documented APIs in our library."
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "If your request to API access is approved, you will get the secret key and synthetic data to start testing."
            ]
          },
        ]
      },
      // {
      //   title: "Testing",
      //   questions: [
      //     {
      //       question: "How can I use your APIs?",
      //       answer: [
      //         "",
      //         ""
      //       ]
      //     },
      //     {
      //       question: "How many APIs do you have?",
      //       answer: [
      //         "",
      //         ""
      //       ]
      //     },
      //     {
      //       question: "When will I get my secret Key?",
      //       answer: [
      //         "",
      //         ""
      //       ]
      //     },
      //   ]
      // },
      // {
      //   title: "Support",
      //   questions: [
      //     {
      //       question: "How can I use your APIs?",
      //       answer: [
      //         "",
      //         ""
      //       ]
      //     },
      //     {
      //       question: "How many APIs do you have?",
      //       answer: [
      //         "",
      //         ""
      //       ]
      //     },
      //     {
      //       question: "When will I get my secret Key?",
      //       answer: [
      //         "",
      //         ""
      //       ]
      //     },
      //   ]
      // },
    ]
  },
  es: {
    groups: [
      {
        title: "About Us",
        questions: [
          {
            question: "Who can Participate?",
            answer: [
              "According to the research firm Frost & Sullivan, the estimated size of the North American used test and measurement equipment market was $446.4 million in 2004 and is estimated to grow to $654.5 million by 2011. For over 50 years, companies and governments have procured used test and measurement instruments in order to realize a number of benefits including the need to:",
              "– reduce equipment acquisition costs",
              "– replace discontinued testing instruments"
            ]
          },
          {
            question: "What are the Open Innovation Programs?",
            answer: [
              "Content"
            ]
          },
        ]
      },
      {
        title: "APIs",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
      {
        title: "Testing",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
      {
        title: "Support",
        questions: [
          {
            question: "How can I use your APIs?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "How many APIs do you have?",
            answer: [
              "",
              ""
            ]
          },
          {
            question: "When will I get my secret Key?",
            answer: [
              "",
              ""
            ]
          },
        ]
      },
    ]
  },
}
